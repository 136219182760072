* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #e8e8e8 !important;
}

.signup input,
.login input,
.resetPass input {
  padding-left: 15px;
  width: 100%;
  width: 100% !important;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #b8bfca;
}

.signup h1,
.login h1 {
  font-size: 25px !important;
  text-align: center;
  color: #212529;
  font-weight: 600;
  padding: 15px 0px;
}

.resetPass h1 {
  margin-top: 20px;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  color: #292c33;
}

.signup h3 {
  font-size: 15px !important;
  text-align: center;
}

.signup svg,
.login svg {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.signup h4,
.login h4 {
  color: #037644 !important;
  font-size: 15px;
  margin-top: 3px !important;
}

.signup .forget {
  text-decoration: none;
}

.signup ::placeholder,
.login ::placeholder,
.resetPass ::placeholder {
  color: #b8bfca;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  &:before {
    content: "";
    background: linear-gradient(to right, transparent, #d9d9d9, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}

.signup .btn1,
.login .btn1,
.resetPass .btn1 {
  height: 50px !important;
  width: 100% !important;
  /* margin-left: 65px; */
  /* margin: 0px 20px; */
  background: #0076ad !important;
  border-radius: 30px !important;
  color: white !important;
  margin-top: 5px !important;
  border: 1px solid #0076ad;
}

.resetPass .btn1 {
  margin-top: 20px !important;
}

.resetPass h6 {
  color: #2a313a;
  margin-bottom: 30px !important;
}

.resetPass .btn2 {
  height: 50px !important;
  width: 100% !important;
  /* margin-left: 65px; */
  background: #eaebef !important;
  border-radius: 30px !important;
  color: black !important;
  margin-top: 20px !important;
  border: #eaebef 1px solid;
}

.signup a,
.login a {
  text-decoration: none !important;
}

.signup .svgg,
.login .svgg {
  width: 90px;
  height: 10px;
}

.signup #checkbox {
  height: 15px;
  width: 15px !important;
}

.signup p {
  font-size: 15px;
}

.signup .card,
.login .card,
.resetPass .card {
  max-width: 600px;
  padding: 30px;
  /* margin: 0px 10px; */
  border-radius: 20px !important;
  margin: auto;
}

.login h1,
.login .btn1 {
  margin-top: 20px !important;
}

.confirm-pasword {
  margin-top: 20px;
}

@media (max-width: 767px) {

  .signup .left-img,
  .login .left-img,
  .resetPass .left-img {
    display: none;
  }

  .signup .card,
  .login .card {
    max-width: 100% !important;
  }

  .login .card {
    margin-top: 15%;
  }

  .resetPass .card {
    margin-top: 30%;
  }

  .resetPass2 .card {
    margin-top: 5%;
  }
}

.left-img {
  padding-left: 0px !important;
}

.first,
.last,
.email,
.password {
  color: #292c33;
  padding: 10px 0px;
  font-weight: bold;
}

.input-group {
  position: relative;
  width: 100%;
}

.toggle-password {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 70%;
  transform: translateY(-50%);
}

.toggle-confirm-password {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 35%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 600px) {

  .signup .svgg,
  .login .svgg {
    width: 35px;
    height: 10px;
  }

  .passwordstrong {
    font-size: 10px !important;
    font-weight: bold;
  }

  .signup h1,
  .login h1 {
    padding: 10px 0px;
  }
}

.navbar-collapse {
  padding-left: 15px !important;
}

.commonheader {
  justify-content: end;
}

.footer {
  padding: 50px 0px;
}

.unorderlist li {
  color: #2a313a;
  font-size: 16px;
  line-height: px;
}

.verify h1 {
  font-size: 24px !important;
}

.verify p {
  text-align: left;
  margin: 20px 0px;
}

@media only screen and (max-width: 600px) {
  .resetPass2 {
    margin-top: 25%;
  }

  .margintop {
    margin-top: 30%;
  }
}

.btn-primary {
  height: 60px;
  background-color: #fff;
  color: #ccc  !important;
  border-color: #fff !important;
  cursor: not-allowed;
}

.btn-primary-1 {
  height: 60px;
  background-color: #fff;
  color: #0076ad  !important;
  border-color: #fff !important;
  cursor: pointer;
}

.custom-dropdown-menu {
  /* Example custom styling for adjusting the position */
  top: auto;
  bottom: 100%;
  left: 0;
  right: auto;
  transform: translateY(-2px); /* Optional: Adjust vertical position */
}

.nav-tabs .dropdown-menu{
  /* top:-50px !important; */
  transform: translateY(-13px)  !important;
}

.custom-dropdown-menu1{
  top: auto;
  left: 0;
  right: auto;
  transform: translateY(-2px); /* Optional: Adjust vertical position */
}

.dropup .dropdown-menu{
  /* margin-bottom: -5.875rem !important;
  margin-left: 110px; */
}

input[type="text"] {
  padding-left: 20px;
  /* Adjust the value based on the space you want before the cursor and placeholder */
  text-indent: 0;
  /* Reset text-indent to 0 */
}

input[type="text"]::-webkit-input-placeholder {
  padding-left: 0px !important;
  /* Adjust the value to match the padding-left of the input */
  text-indent: 0;
  /* Reset text-indent to 0 */
}

::-webkit-scrollbar {
  width: 13px; /* Set the width of the vertical scrollbar */
}