@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #F5F5F5 !important;
}

.main {
    margin-top: 90px;
}

.input-icons i {
    position: absolute;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
    margin-bottom: 3px;
}

.card-contain {
    display: flex;
    align-items: center;
}

/* .card {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
} */

.chart {
    background-color: #FFF;
    border-radius: 8px;
}

.card-contain p {
    color: #292C33;
    margin-left: 10px;
    margin-top: 12px;

}

.card-contain svg {
    margin-left: 15px;
}

.blue-card h2 {
    font-weight: bold;
    padding-left: 15px;
}

.padding-leftright-align {
    padding: 0px 20px;
}

.orange-card h2 {
    padding-left: 15px;
}

.orange-text {
    font-weight: 800;
    color: #FF8A01;
}

.orange {
    border: 1px solid #FF8A01 !important;
}

.light-orange-text {
    font-weight: 800;
    color: #EBAB0C;
}

.orange-bg {

    background-color: #AE7008;
}

.blue-bg {
    background-color: #153799;
}

.green-bg {
    background-color: #25966D;
}

.dash-chip {
    color: #FFF;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.light-blue-text {
    font-weight: 800;
    color: #00D3DB;
}

.dark-blue-text {
    font-weight: 800;
    color: #015384;
}

.red-text {
    font-weight: 800;
    color: #EA4335;
}

.filter {
    margin-top: 20px;

}

.btn-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.resume-btn-filter-active {
    color: #FFF !important;
    background-color: #00B5E9;
}

.resume-btn-filter-active:hover {
    color: #FFF !important;
    background-color: #00B5E9;
}

.cv-btn-filter-active:hover {
    color: #FFF !important;
    background-color: #FF8A01;
}

.cv-btn-filter-active {
    color: #FFF !important;
    background-color: #FF8A01;
}

.cv-btn-outline-secondary {
    color: #64748B;
    border: 1px solid #FF8A01;
}

.resume-btn-outline-secondary {
    color: #64748B;
    border: 1px solid #00B5E9;
}

.cv-btn-outline-secondary:hover {
    color: #64748B;
    border: 1px solid #FF8A01;
}

.resume-btn-outline-secondary:hover {
    color: #64748B;
    border: 1px solid #00B5E9;
}

.property {
    margin-left: 20px;
}

.catd {
    border-radius: 0.5rem !important;
}

@media only screen and (max-width: 425px) {
    .property {
        margin-top: 10px;
        margin-left: 0px;
    }

    .chart-m {
        margin-top: 10px !important;
    }

    .mx-2 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .btn-sm {
        font-size: 8px;
    }

    .ms-1 {
        margin-left: 0px !important;
    }

    .mobilv .mx-1 {
        margin-left: 0px !important;
    }

    .mobilv .ms-0 {
        margin-left: 0px !important;
    }
}