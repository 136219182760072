/* .dot-spinner {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  
  .dot-spinner div {
    position: absolute;
    width: 2.7px;
    height: 2.7px; 
    background: #808080;
    border-radius: 50%;
    animation: dot-spinner 1.2s linear infinite;
  }
  
  .dot-spinner div:nth-child(1) {
    animation-delay: 0s;
    top: 11px;
    left: 19px;
  }
  
  .dot-spinner div:nth-child(2) {
    animation-delay: -0.1s;
    top: 6px;
    left: 17px;
  }
  
  .dot-spinner div:nth-child(3) {
    animation-delay: -0.2s;
    top: 3px;
    left: 13px;
  }
  
  .dot-spinner div:nth-child(4) {
    animation-delay: -0.3s;
    top: 2px;
    left: 9px;
  }
  
  .dot-spinner div:nth-child(5) {
    animation-delay: -0.4s;
    top: 3px;
    left: 5px;
  }
  
  .dot-spinner div:nth-child(6) {
    animation-delay: -0.5s;
    top: 6px;
    left: 2px;
  }
  
  .dot-spinner div:nth-child(7) {
    animation-delay: -0.6s;
    top: 11px;
    left: 1px;
  }
  
  .dot-spinner div:nth-child(8) {
    animation-delay: -0.7s;
    top: 16px;
    left: 2px;
  }
  
  .dot-spinner div:nth-child(9) {
    animation-delay: -0.8s;
    top: 19px;
    left: 5px;
  }
  
  .dot-spinner div:nth-child(10) {
    animation-delay: -0.9s;
    top: 20px;
    left: 9px;
  }
  
  .dot-spinner div:nth-child(11) {
    animation-delay: -1s;
    top: 19px;
    left: 13px;
  }
  
  .dot-spinner div:nth-child(12) {
    animation-delay: -1.1s;
    top: 16px;
    left: 17px;
  }
  
  @keyframes dot-spinner {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
   */

   .loader-spiner {
    font-size: 4.5px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #6c8989, 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.5), -1.8em -1.8em 0 0em rgba(108,137,137, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.7), 1.8em -1.8em 0 0em #6c8989, 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.5), 1.8em -1.8em 0 0em rgba(108,137,137, 0.7), 2.5em 0em 0 0em #6c8989, 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.5), 2.5em 0em 0 0em rgba(108,137,137, 0.7), 1.75em 1.75em 0 0em #6c8989, 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.5), 1.75em 1.75em 0 0em rgba(108,137,137, 0.7), 0em 2.5em 0 0em #6c8989, -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.5), 0em 2.5em 0 0em rgba(108,137,137, 0.7), -1.8em 1.8em 0 0em #6c8989, -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.5), -1.8em 1.8em 0 0em rgba(108,137,137, 0.7), -2.6em 0em 0 0em #6c8989, -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.5), -2.6em 0em 0 0em rgba(108,137,137, 0.7), -1.8em -1.8em 0 0em #6c8989;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #6c8989, 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.5), -1.8em -1.8em 0 0em rgba(108,137,137, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.7), 1.8em -1.8em 0 0em #6c8989, 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.5), 1.8em -1.8em 0 0em rgba(108,137,137, 0.7), 2.5em 0em 0 0em #6c8989, 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.5), 2.5em 0em 0 0em rgba(108,137,137, 0.7), 1.75em 1.75em 0 0em #6c8989, 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.5), 1.75em 1.75em 0 0em rgba(108,137,137, 0.7), 0em 2.5em 0 0em #6c8989, -1.8em 1.8em 0 0em rgba(108,137,137, 0.2), -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.5), 0em 2.5em 0 0em rgba(108,137,137, 0.7), -1.8em 1.8em 0 0em #6c8989, -2.6em 0em 0 0em rgba(108,137,137, 0.2), -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.5), -1.8em 1.8em 0 0em rgba(108,137,137, 0.7), -2.6em 0em 0 0em #6c8989, -1.8em -1.8em 0 0em rgba(108,137,137, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(108,137,137, 0.2), 1.8em -1.8em 0 0em rgba(108,137,137, 0.2), 2.5em 0em 0 0em rgba(108,137,137, 0.2), 1.75em 1.75em 0 0em rgba(108,137,137, 0.2), 0em 2.5em 0 0em rgba(108,137,137, 0.2), -1.8em 1.8em 0 0em rgba(108,137,137, 0.5), -2.6em 0em 0 0em rgba(108,137,137, 0.7), -1.8em -1.8em 0 0em #6c8989;
    }
  }
  
  
  