body {
  background-color: #fff !important;
}

/* Nav bar Styles */

.resume-bulder-class a svg {
  margin-right: 5px;
}

.resume-bulder-class nav div div ul li a {
  font-size: 16px !important;
}

.ourwork-tab-wrap {
  margin-top: 90px;
}

.left-tabs {
  background-color: #f0f1f3;
  padding: 6px;
  border-radius: 12px;
  text-align: center;
}

.left {
  padding-right: 20px;
}

#collapse-A p {
  margin-bottom: 5px;
}

#pane-B {
  padding-top: 0px;
}

.right-tabs {
  background-color: #fff;
  padding: 6px;
  border: 1px solid rgb(208, 208, 208) !important;
  border-radius: 12px;
  text-align: center;
}

.btn-outline-info {
  background-color: #e6f1f7 !important;
  border: 1px solid #0076ad;
  color: #000;
  padding: 0.15rem 1rem;
  font-size: 12px;
  color: #0076ad;
}

.new-card-body ul {
  padding: 1% 0px;
  border: 1px solid rgb(255, 255, 255);
}

#tabs ul {
  padding-top: 0px 0px 0px 0px !important;
}

#content {
  padding: 0px !important;
}

#pane-AA {
  padding: 0px;
}

.new-card-body {
  padding-top: 0px;
}

.right-tabs .bg {
  margin: 0px 0px !important;
  background-color: #e6f1f7;
}

.right {
  border-left: 1px solid #e3e3e3;
  padding-left: 20px;
}

.demo-resume {
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  background-color: #f8fbff;
  min-height: 440px !important;
}

.left-tabs li,
.right-tabs li {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.all-tab-img img:nth-last-child(2) {
  padding-bottom: 0;
}

/* 
  .ourwork-tab-wrap .nav-tabs {
      display: none;
      border-bottom: none;
  } */
.btn-linkedIn {
  color: inherit;
  border: none;
  background-color: transparent;
}

.ourwork-tab-wrap .nav-tabs .nav-item {
  margin-right: 10px;
  margin-bottom: 0px;
}

.ourwork-tab-wrap .nav-tabs .nav-link {
  border: none;
  /* color: #000 !important; */
  color: #0076AD !important;
}

.tab-pane.show {
  display: block;
}

.tab-pane {
  display: none;
}

.menu-tabs {
  border-radius: 6px !important;
  cursor: pointer;
  border: 1px solid rgb(208, 208, 208) !important;
  border-bottom: 1px solid rgb(208, 208, 208) !important;
  font-size: 12px;
}

.ourwork-tab-wrap .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #0076ad !important;
  color: #fff !important;
  border-radius: 6px;
}

.steps {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border: 1px solid #e3e3e3;
  padding: 20px 20px 20px 0px;
  border-radius: 6px;
}

.data {
  border: 1px solid #e3e3e3;
  padding: 20px;
  border-radius: 6px;
  margin-top: 10px;
  min-height: 450px;
}

.step-button {
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #cfd4db;
  transition: 0.4s;
}

.step-button[aria-expanded="true"] {
  width: 30px;
  height: 30px;
  background-color: #292c33;
  color: #292c33;
}

.card .step-hide {
  display: none !important;
}

.card .step-hide.show {
  display: block !important;
}

.step-title {
  font-size: 12px;
}

.done {
  background-color: #0076ad;
  color: #fff;
}

.step-item {
  z-index: 10;
  text-align: center;
  max-width: 70px;
}

.show {
  /*display: block;*/
}

#tabs .nav-item {
  padding: 0px;
  margin: 0px 3px;
}

#tabs .nav-item-1 {
  max-height: 80px;
}

#progress {
  -webkit-appearance: none;
  position: absolute;
  top: 38px;
  width: 80%;
  z-index: 5;
  height: 2px;
  margin-left: 18px;
  margin-bottom: 18px;
}

/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: #0076ad;
  transition: 0.5s ease;
}

#progress::-webkit-progress-bar {
  background-color: #e4e4e4;
}

.text-tab {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 10px;
}

.text-tab h1 {
  font-size: 16px !important;
  font-family: Arial, Helvetica, sans-serif;
  color: #292c33;
  text-align: left;
  font-weight: bold !important;
}

.text-tab ul {
  text-decoration: none;
  color: #292c33;
  list-style: none;
}

.text-tab h4 {
  font-size: 15px !important;
  color: #54565c;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 10px;
}

.text-tab p {
  font-size: 14px;
  color: #54565c;
  font-family: Arial, Helvetica, sans-serif;
}

.proffesional-summery h1,
.education h1,
.employeement h1,
.personal-dtls h1,
.contact-info h1 {
  color: #292c33;
  text-align: left;
}

.proffesional-summery .para,
.employeement .para,
.education .para,
.personal-dtls .para,
.contact-info .para {
  color: #64748b;
}

.proffesional-summery label,
.employeement label,
.education label,
.personal-dtls label,
.contact-info label {
  color: #64748b;
  font-size: 16px !important;
}

.proffesional-summery input,
.employeement input,
.education input,
.personal-dtls input,
.skills input,
.contact-info input {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 2px solid #b8bfca;
  padding-left: 10px !important;
}

.chip-css {
  padding: 7px 20px 7px 20px;
  margin-bottom: 10px;
  font-size: 12px;
  background: #f0f1f3;
  border-radius: 30px;
  color: #292c33;
  margin-top: 0px;
  border: 1px solid #cfd4db;
  width: auto;
}

.proffesional-summery input ::placeholder,
.employeement input ::placeholder,
.education input ::placeholder,
.personal-dtls input ::placeholder,
.contact-info input ::placeholder {
  padding-left: 20px;
  color: #292c33;
  font-weight: 500;
}

.proffesional-summery p,
.employeement p,
.education p,
.personal-dtls p,
.contact-info p {
  margin-top: 10px !important;
  font-weight: 500;
  color: #292c33;
}

.proffesional-summery .ipt,
.employeement .ipt,
.education .ipt,
.personal-dtls .ipt,
.contact-info .ipt,
.add-extra .ipt,
.skills .ipt {
  background-color: #f0f1f3;
  padding: 10px;
  width: 100% !important;
  border: none !important;
  border-radius: 6px;
  margin-top: 10px;
}

.btn1 {
  height: 50px !important;
  width: 200px !important;
  /* margin-left: 65px; */
  /* margin: 0px 20px; */
  background: #0076ad !important;
  border-radius: 30px !important;
  color: white !important;
  margin-top: 20px !important;
  border: 1px solid #0076ad;
}

.btn2 {
  /*   height: 50px !important; */
  width: 200px !important;
  /* margin-left: 65px; */
  /* margin: 0px 20px; */
  background: white !important;
  border-radius: 30px !important;
  color: #292c33 !important;
  margin-top: 20px !important;
  border: 1px solid #cfd4db;
}

.chip {
  padding: 5px 10px;
  font-size: 12px;
  background: #f0f1f3 !important;
  border-radius: 30px !important;
  color: #292c33 !important;
  margin-top: 0px !important;
  border: 1px solid #cfd4db;
  width: auto;
}

.p {
  padding-left: 5px !important;
}

#tabs .nav-item {
  margin-right: 5px !important;
}

@media (min-width: 768px) {
  .ourwork-tab-wrap .nav-tabs {
    display: flex;
  }

  .ourwork-tab-wrap .card {
    border: none;
  }

  .ourwork-tab-wrap .card .card-header {
    display: none;
  }

  .ourwork-tab-wrap .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .text-tab {
    margin: 0px 5px;
  }

  .new-card-body {
    padding: 0px;
  }

  .left {
    padding: 0px;
  }

  .right {
    border: none;
    padding: 0px;
  }

  .right-tabs .bg.m {
    margin-top: 5px !important;
  }

  .left-tabs {
    background-color: transparent;
  }

  .ourwork-tab-wrap {
    background-color: #fff !important;
  }

  .ourwork-tab-wrap .tab-content>.tab-pane {
    /* display: block !important; */
    opacity: 1;
    margin-bottom: 10px;
  }

  .ourwork-tab-wrap .card-header {
    background: #427fad;
    color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .ourwork-tab-wrap .card-header h5 a {
    background: #427fad;
    color: #fff;
    border-bottom: none;
    text-decoration: none;
    font-size: 16px;
    display: block;
  }

  .ourwork-tab-wrap .card-header h5 a i {
    float: right;
    padding-top: 5px;
  }

  .ourwork-tab-wrap .card-body {
    border: 1px solid #427fad;
  }

  .ourwork-tab-wrap .card-header h5 a:hover {
    color: #fff;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1230px) {
  .menu-tabs {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .ourwork-tab-wrap .card-body {
    border: none;
  }

  .p {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .menu-tabs {
    font-size: 10px;
    margin-top: 5px;
  }

  .demo-resume button {
    font-size: 12px;
  }

  .ourwork-tab-wrap .nav-tabs {
    display: flex;
  }

  .ourwork-tab-wrap .card {
    border: none;
  }

  .ourwork-tab-wrap .card .card-header {
    display: none;
  }

  .ourwork-tab-wrap .card .collapse {
    display: block;
  }

  .text-tab h1 {
    font-size: 14px !important;
    font-family: Arial, Helvetica, sans-serif;
    color: #292c33;
    font-weight: bold !important;
  }

  .text-tab ul {
    text-decoration: none;
    color: #292c33;
    list-style: none;
    font-size: 12px;
  }

  .text-tab h4 {
    font-size: 12px !important;
    color: #54565c;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 10px;
  }

  .text-tab p {
    font-size: 12px;
    color: #54565c;
    font-family: Arial, Helvetica, sans-serif;
  }

  .proffesional-summery h1,
  .education h1,
  .employeement h1,
  .personal-dtls h1,
  .contact-info h1 {
    font-size: 16px;
    color: #292c33;
  }

  .proffesional-summery .para,
  .employeement .para,
  .education .para,
  .personal-dtls .para,
  .contact-info .para {
    font-size: 12px;
    color: #64748b;
  }

  .proffesional-summery label,
  .employeement label,
  .education label,
  .personal-dtls label,
  .contact-info label {
    color: #64748b;
    font-size: 12px !important;
  }

  .proffesional-summery input,
  .employeement input,
  .education input,
  .personal-dtls input,
  .contact-info input {
    width: 100%;
    height: 35px;
    border-radius: 10px;
    border: 2px solid #b8bfca;
    padding-left: 10px !important;
    font-size: 12px !important;
  }

  .proffesional-summery input ::placeholder,
  .employeement input ::placeholder,
  .education input ::placeholder,
  .personal-dtls input ::placeholder,
  .contact-info input ::placeholder {
    padding-left: 20px;
    color: #292c33;
    font-weight: 500;
  }

  .proffesional-summery p,
  .employeement p,
  .education p,
  .personal-dtls p,
  .contact-info p {
    font-size: 12px;
    margin-top: 10px !important;
    font-weight: 500;
    color: #292c33;
  }

  .proffesional-summery .ipt,
  .employeement .ipt,
  .education .ipt,
  .personal-dtls .ipt,
  .contact-info .ipt,
  .add-extra .ipt,
  .skills .ipt {
    background-color: #f0f1f3;
    padding: 10px;
    width: 100% !important;
    border: none !important;
    border-radius: 6px;
    margin-top: 10px;
  }

  .btn1 {
    height: 30px !important;
    width: 100px !important;
    /* margin-left: 65px; */
    /* margin: 0px 20px; */
    background: #0076ad !important;
    border-radius: 30px !important;
    color: white !important;
    margin-top: 20px !important;
    border: 1px solid #0076ad;
  }

  .btn2 {
    height: 30px !important;
    width: 100px !important;
    /* margin-left: 65px; */
    /* margin: 0px 20px; */
    background: white !important;
    border-radius: 30px !important;
    color: #292c33 !important;
    margin-top: 20px !important;
    border: 1px solid #cfd4db;
  }

  .chip {
    padding: 3px 8px;
    font-size: 10px;
    background: #f0f1f3 !important;
    border-radius: 30px !important;
    color: #292c33 !important;
    margin-top: 20px !important;
    border: 1px solid #cfd4db;
  }

  .step-button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: none;
    background-color: #cfd4db;
    transition: 0.4s;
  }

  .step-button[aria-expanded="true"] {
    width: 10px;
    height: 10px;
    background-color: #292c33;
    color: #292c33;
  }

  .step-title {
    font-size: 8px;
  }

  #progress {
    -webkit-appearance: none;
    position: absolute;
    top: 35px;
    width: 75%;
    z-index: 5;
    height: 1px;
    margin-left: 18px;
    margin-bottom: 18px;
  }

  /* to customize progress bar */
  #progress::-webkit-progress-value {
    background-color: #e4e4e4;
    transition: 0.5s ease;
  }

  #progress::-webkit-progress-bar {
    background-color: #e4e4e4;
  }

  #step7 h1 {
    font-size: 18px !important;
  }

  #step7 p {
    font-size: 12px !important;
  }
}

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.box1 {
  padding: 0px;
  margin-top: 10px;
}

.dcs *,
.dcs *::before,
.dcs *::after {
  box-sizing: border-box;
}

.dcs main {
  display: block;
}

.dcs {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dcs [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

.dcs hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

.dcs hr:not([size]) {
  height: 1px;
}

.dcs h1,
.dcs .h1,
.dcs h2,
.dcs .h2,
.dcs h3,
.dcs .h3,
.dcs h4,
.dcs .h4,
.dcs h5,
.dcs .h5,
.dcs h6,
.dcs .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.dcs h1,
.dcs .h1 {
  font-size: 2.5rem;
}

.dcs h2,
.dcs .h2 {
  font-size: 2rem;
}

.dcs h3,
.dcs .h3 {
  font-size: 1.75rem;
}

.dcs h4,
.dcs .h4 {
  font-size: 1.5rem;
}

.dcs h5,
.dcs .h5 {
  font-size: 1.25rem;
}

.dcs h6,
.dcs .h6 {
  font-size: 1rem;
}

.dcs p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.dcs abbr[title],
.dcs abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

.dcs address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

.dcs ol,
.dcs ul,
.dcs dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.dcs ol ol,
.dcs ul ul,
.dcs ol ul,
.dcs ul ol {
  margin-bottom: 0;
}

.dcs dt {
  font-weight: 700;
}

.dcs dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

.dcs blockquote {
  margin: 0 0 1rem;
}

.dcs b,
.dcs strong {
  font-weight: bolder;
}

.dcs small,
.dcs .small {
  font-size: 80%;
}

.dcs sub,
.dcs sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.dcs sub {
  bottom: -0.25em;
}

.dcs sup {
  top: -0.5em;
}

.dcs a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.dcs a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.dcs a:not([href]):not([tabindex]),
.dcs a:not([href]):not([tabindex]):hover,
.dcs a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

.dcs pre,
.dcs code,
.dcs kbd,
.dcs samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

.dcs pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

.dcs figure {
  margin: 0 0 1rem;
}

.dcs img {
  vertical-align: middle;
  border-style: none;
}

.dcs svg {
  overflow: hidden;
  vertical-align: middle;
}

.dcs table {
  border-collapse: collapse;
}

.dcs caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

.dcs th {
  text-align: inherit;
}

.dcs label {
  display: inline-block;
  margin-bottom: 0;
}

.dcs button {
  border-radius: 0;
}

.dcs button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.dcs input,
.dcs button,
.dcs select,
.dcs optgroup,
.dcs textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.dcs button,
.dcs input {
  overflow: visible;
}

.dcs button,
.dcs select {
  text-transform: none;
}

.dcs select {
  word-wrap: normal;
}

.dcs button,
.dcs [type="button"],
.dcs [type="reset"],
.dcs [type="submit"] {
  -webkit-appearance: button;
}

.dcs button:not(:disabled),
.dcs [type="button"]:not(:disabled),
.dcs [type="reset"]:not(:disabled),
.dcs [type="submit"]:not(:disabled) {
  cursor: pointer;
}

.dcs button::-moz-focus-inner,
.dcs [type="button"]::-moz-focus-inner,
.dcs [type="reset"]::-moz-focus-inner,
.dcs [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.dcs input[type="radio"],
.dcs input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.dcs input[type="date"],
.dcs input[type="time"],
.dcs input[type="datetime-local"],
.dcs input[type="month"] {
  -webkit-appearance: listbox;
}

.dcs textarea {
  overflow: auto;
  resize: vertical;
}

.dcs fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.dcs legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.dcs mark,
.dcs .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.dcs progress {
  vertical-align: baseline;
}

.dcs [type="number"]::-webkit-inner-spin-button,
.dcs [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.dcs [type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

.dcs [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.dcs ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.dcs output {
  display: inline-block;
}

.dcs summary {
  display: list-item;
  cursor: pointer;
}

.dcs template {
  display: none;
}

.dcs [hidden] {
  display: none !important;
}

.dcs .lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.dcs .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.dcs .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.dcs .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.dcs .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.dcs .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.dcs .list-inline {
  padding-left: 0;
  list-style: none;
}

.dcs .list-inline-item {
  display: inline-block;
}

.dcs .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.dcs .initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.dcs .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.dcs .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.dcs .blockquote-footer::before {
  content: "\2014\00A0";
}

.dcs .container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .dcs .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .dcs .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .dcs .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .dcs .container {
    max-width: 1140px;
  }
}

.dcs .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.dcs .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.dcs .no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.dcs .no-gutters>.col,
.dcs .no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.dcs .col-1,
.dcs .col-2,
.dcs .col-3,
.dcs .col-4,
.dcs .col-5,
.dcs .col-6,
.dcs .col-7,
.dcs .col-8,
.dcs .col-9,
.dcs .col-10,
.dcs .col-11,
.dcs .col-12,
.dcs .col,
.dcs .col-auto,
.dcs .col-sm-1,
.dcs .col-sm-2,
.dcs .col-sm-3,
.dcs .col-sm-4,
.dcs .col-sm-5,
.dcs .col-sm-6,
.dcs .col-sm-7,
.dcs .col-sm-8,
.dcs .col-sm-9,
.dcs .col-sm-10,
.dcs .col-sm-11,
.dcs .col-sm-12,
.dcs .col-sm,
.dcs .col-sm-auto,
.dcs .col-md-1,
.dcs .col-md-2,
.dcs .col-md-3,
.dcs .col-md-4,
.dcs .col-md-5,
.dcs .col-md-6,
.dcs .col-md-7,
.dcs .col-md-8,
.dcs .col-md-9,
.dcs .col-md-10,
.dcs .col-md-11,
.dcs .col-md-12,
.dcs .col-md,
.dcs .col-md-auto,
.dcs .col-lg-1,
.dcs .col-lg-2,
.dcs .col-lg-3,
.dcs .col-lg-4,
.dcs .col-lg-5,
.dcs .col-lg-6,
.dcs .col-lg-7,
.dcs .col-lg-8,
.dcs .col-lg-9,
.dcs .col-lg-10,
.dcs .col-lg-11,
.dcs .col-lg-12,
.dcs .col-lg,
.dcs .col-lg-auto,
.dcs .col-xl-1,
.dcs .col-xl-2,
.dcs .col-xl-3,
.dcs .col-xl-4,
.dcs .col-xl-5,
.dcs .col-xl-6,
.dcs .col-xl-7,
.dcs .col-xl-8,
.dcs .col-xl-9,
.dcs .col-xl-10,
.dcs .col-xl-11,
.dcs .col-xl-12,
.dcs .col-xl,
.dcs .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.dcs .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.dcs .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.dcs .col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.dcs .col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.dcs .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.dcs .col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.dcs .col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.dcs .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.dcs .col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.dcs .col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.dcs .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.dcs .col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.dcs .col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.dcs .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.dcs .order-first {
  order: -1;
}

.dcs .order-last {
  order: 13;
}

.dcs .order-0 {
  order: 0;
}

.dcs .order-1 {
  order: 1;
}

.dcs .order-2 {
  order: 2;
}

.dcs .order-3 {
  order: 3;
}

.dcs .order-4 {
  order: 4;
}

.dcs .order-5 {
  order: 5;
}

.dcs .order-6 {
  order: 6;
}

.dcs .order-7 {
  order: 7;
}

.dcs .order-8 {
  order: 8;
}

.dcs .order-9 {
  order: 9;
}

.dcs .order-10 {
  order: 10;
}

.dcs .order-11 {
  order: 11;
}

.dcs .order-12 {
  order: 12;
}

.dcs .offset-1 {
  margin-left: 8.333333%;
}

.dcs .offset-2 {
  margin-left: 16.666667%;
}

.dcs .offset-3 {
  margin-left: 25%;
}

.dcs .offset-4 {
  margin-left: 33.333333%;
}

.dcs .offset-5 {
  margin-left: 41.666667%;
}

.dcs .offset-6 {
  margin-left: 50%;
}

.dcs .offset-7 {
  margin-left: 58.333333%;
}

.dcs .offset-8 {
  margin-left: 66.666667%;
}

.dcs .offset-9 {
  margin-left: 75%;
}

.dcs .offset-10 {
  margin-left: 83.333333%;
}

.dcs .offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .dcs .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .dcs .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .dcs .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .dcs .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .dcs .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .dcs .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .dcs .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .dcs .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .dcs .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .dcs .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .dcs .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .dcs .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .dcs .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .dcs .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dcs .order-sm-first {
    order: -1;
  }

  .dcs .order-sm-last {
    order: 13;
  }

  .dcs .order-sm-0 {
    order: 0;
  }

  .dcs .order-sm-1 {
    order: 1;
  }

  .dcs .order-sm-2 {
    order: 2;
  }

  .dcs .order-sm-3 {
    order: 3;
  }

  .dcs .order-sm-4 {
    order: 4;
  }

  .dcs .order-sm-5 {
    order: 5;
  }

  .dcs .order-sm-6 {
    order: 6;
  }

  .dcs .order-sm-7 {
    order: 7;
  }

  .dcs .order-sm-8 {
    order: 8;
  }

  .dcs .order-sm-9 {
    order: 9;
  }

  .dcs .order-sm-10 {
    order: 10;
  }

  .dcs .order-sm-11 {
    order: 11;
  }

  .dcs .order-sm-12 {
    order: 12;
  }

  .dcs .offset-sm-0 {
    margin-left: 0;
  }

  .dcs .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .dcs .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .dcs .offset-sm-3 {
    margin-left: 25%;
  }

  .dcs .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .dcs .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .dcs .offset-sm-6 {
    margin-left: 50%;
  }

  .dcs .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .dcs .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .dcs .offset-sm-9 {
    margin-left: 75%;
  }

  .dcs .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .dcs .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .dcs .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .dcs .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .dcs .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .dcs .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .dcs .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .dcs .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .dcs .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .dcs .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .dcs .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .dcs .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .dcs .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .dcs .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .dcs .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .dcs .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dcs .order-md-first {
    order: -1;
  }

  .dcs .order-md-last {
    order: 13;
  }

  .dcs .order-md-0 {
    order: 0;
  }

  .dcs .order-md-1 {
    order: 1;
  }

  .dcs .order-md-2 {
    order: 2;
  }

  .dcs .order-md-3 {
    order: 3;
  }

  .dcs .order-md-4 {
    order: 4;
  }

  .dcs .order-md-5 {
    order: 5;
  }

  .dcs .order-md-6 {
    order: 6;
  }

  .dcs .order-md-7 {
    order: 7;
  }

  .dcs .order-md-8 {
    order: 8;
  }

  .dcs .order-md-9 {
    order: 9;
  }

  .dcs .order-md-10 {
    order: 10;
  }

  .dcs .order-md-11 {
    order: 11;
  }

  .dcs .order-md-12 {
    order: 12;
  }

  .dcs .offset-md-0 {
    margin-left: 0;
  }

  .dcs .offset-md-1 {
    margin-left: 8.333333%;
  }

  .dcs .offset-md-2 {
    margin-left: 16.666667%;
  }

  .dcs .offset-md-3 {
    margin-left: 25%;
  }

  .dcs .offset-md-4 {
    margin-left: 33.333333%;
  }

  .dcs .offset-md-5 {
    margin-left: 41.666667%;
  }

  .dcs .offset-md-6 {
    margin-left: 50%;
  }

  .dcs .offset-md-7 {
    margin-left: 58.333333%;
  }

  .dcs .offset-md-8 {
    margin-left: 66.666667%;
  }

  .dcs .offset-md-9 {
    margin-left: 75%;
  }

  .dcs .offset-md-10 {
    margin-left: 83.333333%;
  }

  .dcs .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .dcs .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .dcs .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .dcs .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .dcs .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .dcs .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .dcs .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .dcs .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .dcs .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .dcs .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .dcs .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .dcs .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .dcs .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .dcs .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .dcs .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dcs .order-lg-first {
    order: -1;
  }

  .dcs .order-lg-last {
    order: 13;
  }

  .dcs .order-lg-0 {
    order: 0;
  }

  .dcs .order-lg-1 {
    order: 1;
  }

  .dcs .order-lg-2 {
    order: 2;
  }

  .dcs .order-lg-3 {
    order: 3;
  }

  .dcs .order-lg-4 {
    order: 4;
  }

  .dcs .order-lg-5 {
    order: 5;
  }

  .dcs .order-lg-6 {
    order: 6;
  }

  .dcs .order-lg-7 {
    order: 7;
  }

  .dcs .order-lg-8 {
    order: 8;
  }

  .dcs .order-lg-9 {
    order: 9;
  }

  .dcs .order-lg-10 {
    order: 10;
  }

  .dcs .order-lg-11 {
    order: 11;
  }

  .dcs .order-lg-12 {
    order: 12;
  }

  .dcs .offset-lg-0 {
    margin-left: 0;
  }

  .dcs .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .dcs .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .dcs .offset-lg-3 {
    margin-left: 25%;
  }

  .dcs .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .dcs .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .dcs .offset-lg-6 {
    margin-left: 50%;
  }

  .dcs .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .dcs .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .dcs .offset-lg-9 {
    margin-left: 75%;
  }

  .dcs .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .dcs .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .dcs .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .dcs .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .dcs .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .dcs .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .dcs .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .dcs .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .dcs .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .dcs .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .dcs .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .dcs .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .dcs .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .dcs .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .dcs .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .dcs .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dcs .order-xl-first {
    order: -1;
  }

  .dcs .order-xl-last {
    order: 13;
  }

  .dcs .order-xl-0 {
    order: 0;
  }

  .dcs .order-xl-1 {
    order: 1;
  }

  .dcs .order-xl-2 {
    order: 2;
  }

  .dcs .order-xl-3 {
    order: 3;
  }

  .dcs .order-xl-4 {
    order: 4;
  }

  .dcs .order-xl-5 {
    order: 5;
  }

  .dcs .order-xl-6 {
    order: 6;
  }

  .dcs .order-xl-7 {
    order: 7;
  }

  .dcs .order-xl-8 {
    order: 8;
  }

  .dcs .order-xl-9 {
    order: 9;
  }

  .dcs .order-xl-10 {
    order: 10;
  }

  .dcs .order-xl-11 {
    order: 11;
  }

  .dcs .order-xl-12 {
    order: 12;
  }

  .dcs .offset-xl-0 {
    margin-left: 0;
  }

  .dcs .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .dcs .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .dcs .offset-xl-3 {
    margin-left: 25%;
  }

  .dcs .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .dcs .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .dcs .offset-xl-6 {
    margin-left: 50%;
  }

  .dcs .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .dcs .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .dcs .offset-xl-9 {
    margin-left: 75%;
  }

  .dcs .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .dcs .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.dcs .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dcs .form-control {
    transition: none;
  }
}

.dcs .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.dcs .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.dcs .form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.dcs .form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.dcs .form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.dcs .form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.dcs .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.dcs .form-control:disabled,
.dcs .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.dcs select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.dcs .form-control-file,
.dcs .form-control-range {
  display: block;
  width: 100%;
}

.dcs .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.dcs .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.dcs .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.dcs .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.dcs .form-control-plaintext.form-control-sm,
.dcs .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.dcs .form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.dcs .form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.dcs select.form-control[size],
.dcs select.form-control[multiple] {
  height: auto;
}

.dcs textarea.form-control {
  height: auto;
}

.dcs .form-group {
  margin-bottom: 1rem;
}

.dcs .form-text {
  display: block;
  margin-top: 0.25rem;
}

.dcs .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.dcs .form-row>.col,
.dcs .form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.dcs .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.dcs .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.dcs .form-check-input[disabled]~.form-check-label {
  color: #6c757d;
}

.dcs .form-check-label {
  margin-bottom: 0;
}

.dcs .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.dcs .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.dcs .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.dcs .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .dcs .form-control:valid,
.dcs .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#28a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .dcs .form-control:valid:focus,
.dcs .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .dcs .form-control:valid~.valid-feedback,
.was-validated .dcs .form-control:valid~.valid-tooltip,
.dcs .form-control.is-valid~.valid-feedback,
.dcs .form-control.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .dcs textarea.form-control:valid,
.dcs textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .dcs .custom-select:valid,
.dcs .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#28a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .dcs .custom-select:valid:focus,
.dcs .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .dcs .custom-select:valid~.valid-feedback,
.was-validated .dcs .custom-select:valid~.valid-tooltip,
.dcs .custom-select.is-valid~.valid-feedback,
.dcs .custom-select.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .dcs .form-control-file:valid~.valid-feedback,
.was-validated .dcs .form-control-file:valid~.valid-tooltip,
.dcs .form-control-file.is-valid~.valid-feedback,
.dcs .form-control-file.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .dcs .form-check-input:valid~.form-check-label,
.dcs .form-check-input.is-valid~.form-check-label {
  color: #28a745;
}

.was-validated .dcs .form-check-input:valid~.valid-feedback,
.was-validated .dcs .form-check-input:valid~.valid-tooltip,
.dcs .form-check-input.is-valid~.valid-feedback,
.dcs .form-check-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .dcs .custom-control-input:valid~.custom-control-label,
.dcs .custom-control-input.is-valid~.custom-control-label {
  color: #28a745;
}

.was-validated .dcs .custom-control-input:valid~.custom-control-label::before,
.dcs .custom-control-input.is-valid~.custom-control-label::before {
  border-color: #28a745;
}

.was-validated .dcs .custom-control-input:valid~.valid-feedback,
.was-validated .dcs .custom-control-input:valid~.valid-tooltip,
.dcs .custom-control-input.is-valid~.valid-feedback,
.dcs .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .dcs .custom-control-input:valid:checked~.custom-control-label::before,
.dcs .custom-control-input.is-valid:checked~.custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .dcs .custom-control-input:valid:focus~.custom-control-label::before,
.dcs .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .dcs .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.dcs .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #28a745;
}

.was-validated .dcs .custom-file-input:valid~.custom-file-label,
.dcs .custom-file-input.is-valid~.custom-file-label {
  border-color: #28a745;
}

.was-validated .dcs .custom-file-input:valid~.valid-feedback,
.was-validated .dcs .custom-file-input:valid~.valid-tooltip,
.dcs .custom-file-input.is-valid~.valid-feedback,
.dcs .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .dcs .custom-file-input:valid:focus~.custom-file-label,
.dcs .custom-file-input.is-valid:focus~.custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.dcs .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.dcs .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .dcs .form-control:invalid,
.dcs .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#dc3545' viewBox='-2 -2 7 7'><path stroke='#dc3545' d='M0 0l3 3m0-3L0 3'/><circle r='.5'/><circle cx='3' r='.5'/><circle cy='3' r='.5'/><circle cx='3' cy='3' r='.5'/></svg>");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .dcs .form-control:invalid:focus,
.dcs .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .dcs .form-control:invalid~.invalid-feedback,
.was-validated .dcs .form-control:invalid~.invalid-tooltip,
.dcs .form-control.is-invalid~.invalid-feedback,
.dcs .form-control.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .dcs textarea.form-control:invalid,
.dcs textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .dcs .custom-select:invalid,
.dcs .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#dc3545' viewBox='-2 -2 7 7'><path stroke='#dc3545' d='M0 0l3 3m0-3L0 3'/><circle r='.5'/><circle cx='3' r='.5'/><circle cy='3' r='.5'/><circle cx='3' cy='3' r='.5'/></svg>") #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .dcs .custom-select:invalid:focus,
.dcs .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .dcs .custom-select:invalid~.invalid-feedback,
.was-validated .dcs .custom-select:invalid~.invalid-tooltip,
.dcs .custom-select.is-invalid~.invalid-feedback,
.dcs .custom-select.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .dcs .form-control-file:invalid~.invalid-feedback,
.was-validated .dcs .form-control-file:invalid~.invalid-tooltip,
.dcs .form-control-file.is-invalid~.invalid-feedback,
.dcs .form-control-file.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .dcs .form-check-input:invalid~.form-check-label,
.dcs .form-check-input.is-invalid~.form-check-label {
  color: #dc3545;
}

.was-validated .dcs .form-check-input:invalid~.invalid-feedback,
.was-validated .dcs .form-check-input:invalid~.invalid-tooltip,
.dcs .form-check-input.is-invalid~.invalid-feedback,
.dcs .form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .dcs .custom-control-input:invalid~.custom-control-label,
.dcs .custom-control-input.is-invalid~.custom-control-label {
  color: #dc3545;
}

.was-validated .dcs .custom-control-input:invalid~.custom-control-label::before,
.dcs .custom-control-input.is-invalid~.custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .dcs .custom-control-input:invalid~.invalid-feedback,
.was-validated .dcs .custom-control-input:invalid~.invalid-tooltip,
.dcs .custom-control-input.is-invalid~.invalid-feedback,
.dcs .custom-control-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .dcs .custom-control-input:invalid:checked~.custom-control-label::before,
.dcs .custom-control-input.is-invalid:checked~.custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .dcs .custom-control-input:invalid:focus~.custom-control-label::before,
.dcs .custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .dcs .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.dcs .custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .dcs .custom-file-input:invalid~.custom-file-label,
.dcs .custom-file-input.is-invalid~.custom-file-label {
  border-color: #dc3545;
}

.was-validated .dcs .custom-file-input:invalid~.invalid-feedback,
.was-validated .dcs .custom-file-input:invalid~.invalid-tooltip,
.dcs .custom-file-input.is-invalid~.invalid-feedback,
.dcs .custom-file-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .dcs .custom-file-input:invalid:focus~.custom-file-label,
.dcs .custom-file-input.is-invalid:focus~.custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.dcs .form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.dcs .form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .dcs .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .dcs .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .dcs .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .dcs .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .dcs .form-inline .input-group,
  .dcs .form-inline .custom-select {
    width: auto;
  }

  .dcs .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .dcs .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .dcs .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .dcs .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.dcs .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dcs .btn {
    transition: none;
  }
}

.dcs .btn:hover {
  color: #212529;
  text-decoration: none;
}

.dcs .btn:focus,
.dcs .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.dcs .btn.disabled,
.dcs .btn:disabled {
  opacity: 0.65;
}

.dcs a.btn.disabled,
.dcs fieldset:disabled a.btn {
  pointer-events: none;
}

.dcs .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.dcs .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.dcs .btn-primary:focus,
.dcs .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.dcs .btn-primary.disabled,
.dcs .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.dcs .btn-primary:not(:disabled):not(.disabled):active,
.dcs .btn-primary:not(:disabled):not(.disabled).active,
.show>.dcs .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.dcs .btn-primary:not(:disabled):not(.disabled):active:focus,
.dcs .btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.dcs .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.dcs .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.dcs .btn-secondary:focus,
.dcs .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.dcs .btn-secondary.disabled,
.dcs .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.dcs .btn-secondary:not(:disabled):not(.disabled):active,
.dcs .btn-secondary:not(:disabled):not(.disabled).active,
.show>.dcs .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.dcs .btn-secondary:not(:disabled):not(.disabled):active:focus,
.dcs .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.dcs .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.dcs .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.dcs .btn-success:focus,
.dcs .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.dcs .btn-success.disabled,
.dcs .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.dcs .btn-success:not(:disabled):not(.disabled):active,
.dcs .btn-success:not(:disabled):not(.disabled).active,
.show>.dcs .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.dcs .btn-success:not(:disabled):not(.disabled):active:focus,
.dcs .btn-success:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.dcs .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.dcs .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.dcs .btn-info:focus,
.dcs .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.dcs .btn-info.disabled,
.dcs .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.dcs .btn-info:not(:disabled):not(.disabled):active,
.dcs .btn-info:not(:disabled):not(.disabled).active,
.show>.dcs .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.dcs .btn-info:not(:disabled):not(.disabled):active:focus,
.dcs .btn-info:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.dcs .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.dcs .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.dcs .btn-warning:focus,
.dcs .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.dcs .btn-warning.disabled,
.dcs .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.dcs .btn-warning:not(:disabled):not(.disabled):active,
.dcs .btn-warning:not(:disabled):not(.disabled).active,
.show>.dcs .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.dcs .btn-warning:not(:disabled):not(.disabled):active:focus,
.dcs .btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.dcs .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.dcs .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.dcs .btn-danger:focus,
.dcs .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.dcs .btn-danger.disabled,
.dcs .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.dcs .btn-danger:not(:disabled):not(.disabled):active,
.dcs .btn-danger:not(:disabled):not(.disabled).active,
.show>.dcs .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.dcs .btn-danger:not(:disabled):not(.disabled):active:focus,
.dcs .btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.dcs .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dcs .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.dcs .btn-light:focus,
.dcs .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.dcs .btn-light.disabled,
.dcs .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dcs .btn-light:not(:disabled):not(.disabled):active,
.dcs .btn-light:not(:disabled):not(.disabled).active,
.show>.dcs .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.dcs .btn-light:not(:disabled):not(.disabled):active:focus,
.dcs .btn-light:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.dcs .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.dcs .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.dcs .btn-dark:focus,
.dcs .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.dcs .btn-dark.disabled,
.dcs .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.dcs .btn-dark:not(:disabled):not(.disabled):active,
.dcs .btn-dark:not(:disabled):not(.disabled).active,
.show>.dcs .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.dcs .btn-dark:not(:disabled):not(.disabled):active:focus,
.dcs .btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.dcs .btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.dcs .btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.dcs .btn-outline-primary:focus,
.dcs .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.dcs .btn-outline-primary.disabled,
.dcs .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.dcs .btn-outline-primary:not(:disabled):not(.disabled):active,
.dcs .btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.dcs .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.dcs .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.dcs .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.dcs .btn-outline-secondary:focus,
.dcs .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.dcs .btn-outline-secondary.disabled,
.dcs .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dcs .btn-outline-secondary:not(:disabled):not(.disabled):active,
.dcs .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.dcs .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.dcs .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.dcs .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.dcs .btn-outline-success:focus,
.dcs .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.dcs .btn-outline-success.disabled,
.dcs .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.dcs .btn-outline-success:not(:disabled):not(.disabled):active,
.dcs .btn-outline-success:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.dcs .btn-outline-success:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.dcs .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.dcs .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.dcs .btn-outline-info:focus,
.dcs .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.dcs .btn-outline-info.disabled,
.dcs .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.dcs .btn-outline-info:not(:disabled):not(.disabled):active,
.dcs .btn-outline-info:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.dcs .btn-outline-info:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.dcs .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.dcs .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.dcs .btn-outline-warning:focus,
.dcs .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.dcs .btn-outline-warning.disabled,
.dcs .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.dcs .btn-outline-warning:not(:disabled):not(.disabled):active,
.dcs .btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.dcs .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.dcs .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.dcs .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.dcs .btn-outline-danger:focus,
.dcs .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.dcs .btn-outline-danger.disabled,
.dcs .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.dcs .btn-outline-danger:not(:disabled):not(.disabled):active,
.dcs .btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.dcs .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.dcs .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.dcs .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dcs .btn-outline-light:focus,
.dcs .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.dcs .btn-outline-light.disabled,
.dcs .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.dcs .btn-outline-light:not(:disabled):not(.disabled):active,
.dcs .btn-outline-light:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dcs .btn-outline-light:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.dcs .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.dcs .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.dcs .btn-outline-dark:focus,
.dcs .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.dcs .btn-outline-dark.disabled,
.dcs .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.dcs .btn-outline-dark:not(:disabled):not(.disabled):active,
.dcs .btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.dcs .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.dcs .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.dcs .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.dcs .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.dcs .btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.dcs .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.dcs .btn-link:focus,
.dcs .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.dcs .btn-link:disabled,
.dcs .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.dcs .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.dcs .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.dcs .btn-block {
  display: block;
  width: 100%;
}

.dcs .btn-block+.btn-block {
  margin-top: 0.5rem;
}

.dcs input[type="submit"].btn-block,
.dcs input[type="reset"].btn-block,
.dcs input[type="button"].btn-block {
  width: 100%;
}

.tox-statusbar__branding {
  display: none;
}

.border-none {
  border-radius: 0px !important;
}

input[type="text"] {
  padding-left: 20px;
  /* Adjust the value based on the space you want before the cursor and placeholder */
  text-indent: 0;
  /* Reset text-indent to 0 */
}

input[type="month"] {
  width: 100% !important;
  height: 45px;
  border-radius: 10px;
  border: 2px solid #b8bfca;
  padding-left: 10px !important;
  padding-right: 15px;
}

input::placeholder {
  color: #d6d9df !important;
}

input[type="text"]::-webkit-input-placeholder {
  padding-left: 0px !important;
  /* Adjust the value to match the padding-left of the input */
  text-indent: 0;
  /* Reset text-indent to 0 */
}

.resume-bulder-class {
  background-color: #fff;
}

.border-none {
  border-radius: 0px !important;
}

input[type="text"] {
  padding-left: 20px;
  /* Adjust the value based on the space you want before the cursor and placeholder */
  text-indent: 0;
  /* Reset text-indent to 0 */
}

input[type="text"]::-webkit-input-placeholder {
  padding-left: 0px !important;
  /* Adjust the value to match the padding-left of the input */
  text-indent: 0;
  /* Reset text-indent to 0 */
}