/* Option 2: Import via CSS */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


.text-content {
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.small {
  font-size: 12px;
}

.btn.button-primary {
  color: #4b5768 !important;
}

.content {
  min-height: 250px;
  /* background-color: rgb(233, 233, 233); */
  color: #00547b;
  background-color: #e0e5f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.row {
  justify-content: space-between;
}


.bi {
  color: #fff;
  padding-right: 10px;
}

footer p {
  padding: 0;
  margin: 0;
}

section {
  padding: 0px !important;
}

.sponsor {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 150px;

  background-color: #f8f6f9;
}

.sponsor img {
  width: 120px;
  margin-right: 30px;
}

.text-sponsor {
  color: #37404c;
  /* margin-top: 30px;*/
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.sponsor-img {
  width: 50%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
}

.sponsor-img img {
  max-width: 70px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1rem;
}


.center-content .text-center {
  width: 60% !important;
  line-height: 25px;
  margin-left: calc(100% - 80%);
  text-align: center;
}

.laptop-img {
  padding: 10px;
}

h1 {
  text-align: center;
}

.work-img {
  height: 600px;
}

.unlock h1 {
  color: #0076ad;
  text-align: left;
  font-weight: 700;
  padding-bottom: 30px;
  font-size: 32px !important;
}

.unlock-header {
  text-align: center !important;
  padding-bottom: 0px !important;
}

.unlock-para {
  position: relative;
  align-self: center;
  font-family: "Inter", Helvetica;
  font-weight: 500;
  color: var(--foundation-graygray-800);
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.4px;
  line-height: 24px;
  width: 55%;
}

.unlock .row .hedding {
  text-align: left !important;
}

.text {
  color: #37404c;
}

.container {
  padding: 30px 0px;
}

.para-container {
  background-color: #e6f1f7;
  padding: 20px;
}

.para p {
  text-align: justify !important;
  /* padding-left: 35px; */
}

.row .hedding {
  color: #00415f !important;
  font-size: 16px;
}

.row .hedding img {
  width: 25px;
  margin-right: 10px;
}

.unlock {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-top: 30px;
}

.main {
  margin-top: 70px;
  background: #f0f1f3;
}

.navbar-brand {
  padding-left: 15px;
  /* margin-right: 10px; */
}

.set-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.main h1 {
  color: #292c33;
  font-size: 10rem !important;
  text-align: left;
  line-height: 56px !important;
}

.main p {
  font-size: 18px !important;
  text-align: justify;
  color: #4b5768;
  line-height: 30px;
}

.home-img {
  height: 100%;
  width: 100%;
}

.btn-primary {
  background-color: #0076ad;
  color: #fff;
}

.centri-section {
  background-color: #fff;
}

.textimonal-section h2 {
  font-weight: 700;
}

@media (max-width: 425px) {
  .text-content {
    max-width: 90%;
    font-size: 20px !important;
  }

  .text-sponsor {
    color: #37404c;
    font-size: 14px;
  }

  .unlock h1 {
    font-size: 24px !important;
  }

  .unlock p {
    font-size: 14px !important;
    text-align: justify !important;
    width: 94% !important;
    margin-left: 10px !important;
  }

  .work-img {
    height: 300px;
  }

  .set-content {
    align-items: center;
    margin-bottom: 20px;
    padding-top: 30px;
  }

  .main h1 {
    font-size: 22px;
    text-align: center;
    color: #292c33;
  }

  .main p {
    font-size: 14px;
    text-align: justify;
    color: #4b5768;
  }

  .main button {
    font-size: 12px;
  }

  .footer h6 {
    font-weight: 600;
    padding-top: 20px;
  }

  .footer-headerhead {
    padding-left: 32px !important;
  }

  .centri-section .row {
    padding: 20px;
  }

  .centri-section .row h1 {
    margin: 20px 0px;
    font-weight: 600;
    padding-top: 30px !important;
  }

  .centri-section-content {
    padding: 0px !important;
  }

  .textimonal-section {
    padding: 30px 15px 30px 15px !important;
    text-align: center;
    color: #0076ad;
    background-color: #dde6f0;
  }

  .start-building-section h2 {
    margin: 10px 0px;
  }

  .start-building-section p {
    margin-bottom: 10px !important;
  }

  .start-building-section {
    padding: 20px 10px !important;
  }

  .content {
    padding: 0px 10px;
  }

  .content h1 {
    text-align: left;
    font-weight: 600;
    font-size: 26px !important;
    line-height: 35px !important;
  }

  .content button {
    text-align: left;
    font-size: 12px;
  }
}

@media (min-width: 425px) {
  .set-content {
    align-items: center;
    margin-bottom: 20px;
  }

  .work-img {
    height: 400px;
    padding-top: 40px;
  }

  .main h1 {
    font-size: 27px;
    text-align: center;
  }

  .main p {
    font-size: 15px;
  }

  .main button {
    font-size: 16px;
  }

  .centri-section .row {
    padding: 20px;
  }

  .centri-section .row h1 {
    margin: 20px 0px;
    font-weight: 600;
  }

  .centri-section-content {
    padding: 0px !important;
  }

  .textimonal-section {
    padding: 30px 15px 30px 15px !important;
    text-align: center;
    color: #0076ad;
    background-color: #dde6f0;
  }

  .start-building-section h2 {
    margin: 10px 0px;
  }

  .start-building-section p {
    margin-bottom: 10px !important;
  }

  .start-building-section {
    padding: 20px 10px !important;
  }

  .content {
    padding: 0px 10px;
  }

  .content h1 {
    text-align: left;
    font-weight: 600;
    font-size: 26px !important;
    line-height: 35px !important;
  }

  .content button {
    text-align: left;
  }

  .centri-img img {
    border-radius: 8px;
    height: 400px;
    object-fit: cover;
  }
}

@media (min-width: 1200px) {

  .h1,
  h1 {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) {
  .text-content {
    max-width: 80%;
    font-size: 30px !important;
  }

  .sponsor-img {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .sponsor-img img {
    max-width: 150px;
  }

  .set-content {
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .work-img {
    height: 550px;
  }

  .main h1 {
    font-size: 25px;
    text-align: left;
  }

  .card-footer {
    padding: 10px 0px 0px 0px !important;
  }

  .center .card {
    color: #64748b;
    /* width: 450px; */
    margin: 0px 30px;
    height: 200px !important;
    border: 1px solid #1d4ed8 !important;
    /* transform: scale(1.5); */
  }

  .center .card {
    margin: 0px 0px;
  }
}

/* If the screen size is smaller than 1200px, set the font-size to 80px */
@media (min-width: 1024px) {
  .text-content {
    max-width: 47%;
    font-size: 28px !important;
  }

  .small {
    font-size: medium;
    margin: 15px 0px 35px 0px;
  }

  .main h1 {
    font-size: 34px;
    text-align: left;
  }

  .hedding {
    font-size: 18px !important;
  }

  .para p {
    font-size: 13px !important;
    /* text-align: justify !important; */
    padding-left: 35px;
  }

  .card-footer {
    padding: 10px 0px 0px 0px !important;
  }

  .centri-img img {
    height: auto;
  }
}

@media (min-width: 1440px) {
  .text-content {
    max-width: 45%;
    font-size: 30px !important;
  }

  .small {
    font-size: 14px;
  }

  .row .hedding {
    color: #00415f !important;
    font-size: 20px;
  }

  .row .hedding img {
    width: 30px;
    margin-right: 10px;
  }

  .work-img {
    height: 700px;
  }

  .main h1 {
    font-size: 3.7rem !important;
    text-align: left;
    font-weight: 800;
    line-height: 65px !important;
  }

  .hedding {
    font-size: 25px !important;
  }

  .para p {
    font-size: 16px;
    line-height: 27px;
  }

  .unlock h1 {
    font-size: 32px;
  }

  .centri-img img {
    border-radius: 8px;
    height: 500px;

  }

  .centri-section h1 {
    font-size: 33px !important;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .set {
    margin-bottom: 0px;
  }

  .centri-section p {
    font-size: 15px !important;
  }


  .textimonal-section {
    padding: 30px 50px 50px 50px !important;
  }
}

.btn-primary {
  background-color: #fff;
  color: #4b5768 !important;
}

/* Second Landing Page Section */

/* Testimonal Section */
.textimonal-section span {
  color: #144473;
  text-transform: uppercase;
  font-size: 14px;
  border: 1px solid #144473;
  padding: 5px 15px;
  border-radius: 20px;
}

.textimonal-section h2 {
  margin: 30px 0px;
}

.textimonal-section p {
  font-weight: 600;
}

/* Carasoul Start */
.card {
  border-radius: 2px !important;
  padding: 10px;
  color: #191d23;
  transition: transform 0.3s;
  display: flex;
  /* justify-content: center; */
  /* height: 150px !important; */
}

.card-title {
  max-width: 80%;
}

.owl-stage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer {
  display: flex;
  background-color: #fff !important;
}

.card-footer h6 {
  text-align: left;
  font-size: 14px;
  margin: 0;
}

.card-title {
  text-align: left;
}

.card-footer div {
  padding-left: 10px;
}

.card-footer p {
  padding: 0;
  font-size: 10px;
}

.owl-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
}

.owl-nav button,
.owl-nav button:hover {
  background: transparent !important;
  border: none;
  font-size: 20px;
}

/* Carsoul End */
/* Centri Section */
.centri-section {
  padding: 50px 0px !important;
}

.centri-section h1 {
  text-align: center;
  text-align: left;
  font-size: 28px;
  color: #0076ad;
}

.hedding svg {
  padding: 3px;
  margin-right: 5px;
  border-radius: 4px;
  background: var(--foundation-blue-blue-50, #e8edfb);
  box-shadow: 2px 2px 8px 0px rgba(79, 81, 148, 0.16);
}

.centri-section h3 {
  font-size: 18px !important;
  color: #292c33;
}

.centri-section p {
  font-size: 14px;
  color: #37404c;
  text-align: justify;
  line-height: 24px;
  font-size: 16px !important;
}

/* Start Bulding Section */
.start-building-section {
  background-color: #fff;
  padding: 60px 20px !important;
}

.start-building-section h2 {
  font-weight: 700;
  color: #0076ad;
}

.start-building-section p {
  font-size: 15px;
  color: #37404c;
  margin-bottom: 50px;
  padding: 20px 0px 40px 0px;
}

.start-building-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-content {
  display: flex;
  align-items: center;
}

footer {
  background-color: #292929;
}

.footer-head {
  color: #fff;
  padding-bottom: 5px;
  font-weight: 600;
}

.footer-para p {
  color: #f7f8f9;
  font-size: 13px;
  padding: 6px 0px;
}

.footer-brand img {
  padding-left: 22px;
}

.centri-section .row h1 {
  padding: 0px;
  margin: 0px;
}

.centri-section .unlock {
  padding: 10px 30px;
}

.set {
  padding: 20px 0px;
}